<template>
  <div>
    <div>
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row class="mb-1">

            <b-col md="4" lg="4" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="filterByTransactionDate"
                  class="form-control"
                  placeholder="Enter Transaction Date Range"
                  :config="{ mode: 'range'}"
              />
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <v-select
                v-model="filterFromAccountId"
                :options="filterFromAccountOptionId"
                :reduce="(item) => item.id"
                v-on:input="filterByAccount"
                label="name"
                placeholder="From Account"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="4" lg="4" xs="12">
              <v-select
                v-model="filterToAccountId"
                :options="filterToAccountOptionId"
                :reduce="(item) => item.id"
                v-on:input="filterByAccount"
                label="name"
                placeholder="To Account"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </div>
    <div>
      <b-card>
        <div>
          <!-- search input -->
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <template
                v-if="$permissionAbility(ACCOUNT_TRANSFER_CREATE, permissions)"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="flex-shrink-0"
                  variant="primary"
                  @click="showModal"
                >
                  Transfer Account
                </b-button>
              </template>
            </div>
          </div>

          <!-- table -->

          <vue-good-table
            styleClass="vgt-table table-custom-style striped"
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :rows="rows"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'created_at', type: 'desc' }],
            }"
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template slot="table-row" slot-scope="props">
              <!-- From Account -->

              <template v-if="props?.column?.field === 'format_from_account'">
                <span>{{ props?.row?.fromAccount?.data?.name }}</span>
                <div class="cell-with-description">
                  <div v-if="props?.row?.fromAccount?.data?.email">
                    <span>
                      Email: <b>{{ props?.row?.fromAccount?.data?.email }}</b>
                    </span>
                  </div>
                  <div v-if="props?.row?.fromAccount?.data?.account_number">
                    <span>
                      Acc No: <b>{{ props?.row?.fromAccount?.data?.account_number }}</b
                      >
                    </span>
                  </div>
                  <div
                    v-if="props?.row?.fromAccount?.data?.account_holder_name"
                  >
                    <span>
                      Holder Name: <b>{{ props?.row?.fromAccount?.data?.account_holder_name }}</b
                      >
                    </span>
                  </div>
                  <div>
                    <span>
                      Currency: <b>{{ props?.row?.fromAccount?.data?.currency }}</b
                      >
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="props?.column?.field === 'format_to_account'">
                <span>{{ props?.row?.toAccount?.data?.name }}</span>
                <div class="cell-with-description">
                  <div v-if="props?.row?.toAccount?.data?.email">
                    <span>
                      Email: <b>{{ props?.row?.toAccount?.data?.email }}</b>
                    </span>
                  </div>
                  <div v-if="props?.row?.toAccount?.data?.account_number">
                    <span>
                      Acc No: <b>{{ props?.row?.toAccount?.data?.account_number }}</b
                      >
                    </span>
                  </div>
                  <div v-if="props?.row?.toAccount?.data?.account_holder_name">
                    <span>
                      Holder Name: <b>{{ props?.row?.toAccount?.data?.account_holder_name }}</b
                      >
                    </span>
                  </div>
                  <div>
                    <span>
                      Currency: <b>{{ props?.row?.toAccount?.data?.currency }}</b
                      >
                    </span>
                  </div>
                </div>
              </template>

              <template v-if="props?.column?.field === 'format_remarks'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-on:click="() => showDescriptionModal(props.row?.remarks)"
                  class="btn-sm cell-with-description"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </template>

              <!--  format_amount -->
              <template v-if="props?.column?.field === 'format_amount'">

                <FormatCurrency :amount="props.row?.amount" :currency="props?.row?.currency" />

              </template>

              <template v-if="props?.column?.field === 'format_currency'">

                <FormatCurrency :type="'currency'" :amount="props.row?.amount" :currency="props?.row?.currency" />

              </template>

              <template v-if="props?.column?.field === 'format_status'">
                <template v-if="props?.row?.status">
                  <b-badge pill variant="light-success">Active</b-badge>
                </template>
                <template v-else-if="!props?.row?.status">
                  <b-badge pill variant="light-danger">Inactive</b-badge>
                </template>
              </template>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
        <b-modal
          id="description-modal"
          centered
          title="Remarks"
          hide-footer
          @hidden="hiddenDescriptionModal"
          no-close-on-backdrop
        >
          <b-card-text>
            {{ modalDescription }}
          </b-card-text>
        </b-modal>
        <b-modal
          id="modal-accounts-transfers-form"
          centered
          :title="
            modelType == 'editModel'
              ? 'Account Transfer Information '
              : 'Account Transfer Information'
          "
          hide-footer
          @hidden="hiddenModal"
          no-close-on-backdrop
        >
          <validation-observer ref="accountTransferValidation">
            <b-form v-on:submit.prevent="validationForm">
              <!-- From account -->
              <b-form-group label="Form Account " label-for="from-account" class="required-label">
                <ValidationProvider
                  name="from account "
                  v-slot="{ errors }"
                  vid="from_account_id"
                  rules="required"
                >
                  <!--  -->
                  <v-select
                    id="from-account"
                    placeholder="Choose an Account "
                    v-model="fromAccountId"
                    :options="fromAccountIdOption"
                    v-on:input="showFilterToAccount"
                    :reduce="(country) => country.id"
                    :selectable="(option) => option.disable"
                    label="name"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- To account -->
              <b-form-group label="To Account " label-for="to-account" class="required-label">
                <ValidationProvider
                  name="to account"
                  v-slot="{ errors }"
                  vid="to_account_id"
                  rules="required"
                >
                  <v-select
                    id="to-account"
                    placeholder="Choose an Account"
                    v-model="toAccountId"
                    :options="toAccountIdOption"
                    v-on:input="showFilterFromAccount"
                    :reduce="(country) => country.id"
                    label="name"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- Amount-->
              <b-form-group label="Amount " label-for="amount " class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="amount "
                  vid="amount "
                  rules="required|numeric|max:255"
                >
                  <b-form-input
                    id="amount"
                    type="number"
                    v-model="amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Balance Amount"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- details -->
              <b-form-group label="Remarks " label-for="Remarks" class="required-label">
                <validation-provider
                  #default="{ errors }"
                  name="remarks"
                  vid="remarks"
                  rules="required"
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Add a Remarks"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- loading button -->
              <template v-if="loading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Submit
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BRow,
  BCol,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { ACCOUNT_TRANSFER_CREATE } from "@/helpers/permissionsConstant";
import { el } from "@fullcalendar/core/internal-common";
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "AccountTransferView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
    BFormDatepicker,
    BRow,
    BCol,
    flatPickr,
    BCardActions
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ACCOUNT_TRANSFER_CREATE,
      modalDescription: "",
      filterStartDate: "",
      filterEndDate: "",
      filterRangeDate: "",
      filterFromAccountId: "",
      filterFromAccountOptionId: [],
      filterToAccountId: "",
      filterToAccountOptionId: [],
      modelType: "",
      allAccounts: "",
      fromAccountId: "",
      fromAccountIdOption: [],
      fromAccountAmount: "",
      toAccountId: "",
      toAccountIdOption: "",
      toAccountAmount: "",
      amount: "",
      remarks: "",
      pageLength: 10,
      columns: [
        {
          label: "Transaction Date",
          field: "created_at",
        },
        {
          label: "From Account",
          field: "format_from_account",
          sortable: false,
        },
        {
          label: "To Account",
          field: "format_to_account",
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Amount",
          field: "format_amount",
        },
        {
          label: "Remarks",
          field: "format_remarks",
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    this.onCreateLoad();
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    async onCreateLoad() {
      try {
        const [accounts] = await Promise.all([this.getActiveAccountInfo()]);

        this.allAccounts = accounts;

        this.fromAccountIdOption = (this.allAccounts?.data?.data || []).map(
          (item) => {
            let name = `${item?.name} (${item?.currency} ${item?.balance})`;
            let id = item?.id;
            let isDisable = false;
            if (item?.balance > 0) {
              isDisable = true;
            }
            return {
              name,
              id,
              disable: isDisable,
              currency: item?.currency,
            };
          }
        );

        this.toAccountIdOption = (this.allAccounts?.data?.data || []).map(
          (item) => {
            let name = `${item?.name} (${item?.currency} ${item?.balance})`;
            let id = item?.id;
            return {
              name,
              id,
              currency: item?.currency,
            };
          }
        );

        this.filterFromAccountOptionId = (
          this.allAccounts?.data?.data || []
        ).map((item) => {
          let name = `${item?.name} (${item?.currency} ${item?.balance})`;
          let id = item?.id;
          return {
            name,
            id,
            currency: item?.currency,
          };
        });

        this.filterToAccountOptionId = (this.allAccounts?.data?.data || []).map(
          (item) => {
            let name = `${item?.name} (${item?.currency} ${item?.balance})`;
            let id = item?.id;
            return {
              name,
              id,
              currency: item?.currency,
            };
          }
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks;
      } else {
        this.modalDescription = "N/A";
      }

      this.$bvModal.show("description-modal");
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide("description-modal");
    },

    formatAccountType(value) {
      return value?.data?.name;
    },
    formatStatus(value) {
      if (value) {
        return "Active";
      }

      return "Inactive";
    },
    showModal() {
      this.$bvModal.show("modal-accounts-transfers-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-accounts-transfers-form");
      this.resetModal();
    },
    resetModal() {
      this.accountId = "";
      this.fromAccountId = "";
      this.fromAccountCurrency = "";
      this.toAccountId = "";
      this.amount = "";
      this.remarks = "";
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all");
    },
    async getAccountTransferHistory(params) {
      return await this.$api.get(
        "api/accounts-transfers?include=fromAccount,toAccount",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterStartDate: params.filterStartDate,
            filterEndDate: params.filterEndDate,
            filterFromAccountId: params.filterFromAccountId,
            filterToAccountId: params.filterToAccountId,
          },
        }
      );
    },
    async showFilterFromAccount() {
      const filterAccount = (this.allAccounts?.data?.data || []).filter(
        (item) => {
          return item?.id != this.toAccountId;
        }
      );

      this.fromAccountIdOption = (filterAccount || []).map((item) => {
        let name = `${item?.name} (${item?.currency} ${item?.balance})`;
        let id = item?.id;
        let isDisable = false;
        if (item?.balance > 0) {
          isDisable = true;
        }
        return {
          name,
          id,
          disable: isDisable,
          currency: item?.currency,
        };
      });
    },

    async showFilterToAccount(value) {
      const selectedAccount = (this.allAccounts?.data?.data || []).find(
        (item) => {
          if (item?.id == value) {
            return {
              currency: item?.currency,
              id: item?.id,
            };
          }
          return null;
        }
      );

      const filterAccount = (this.allAccounts?.data?.data || []).filter(
        (item) => {
          return (
            item?.id != selectedAccount?.id &&
            item?.currency == selectedAccount?.currency
          );
        }
      );

      this.toAccountIdOption = (filterAccount || []).map((item) => {
        let name = `${item?.name} (${item?.currency} ${item?.balance})`;
        let id = item?.id;

        return {
          name,
          id,
          currency: item?.currency,
        };
      });
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.accountId = value?.id;
      this.name = value?.name;
      this.accountTypeId = value?.account_type_id;
      this.accountNumber = value?.account_number;
      this.accountHolderName = value?.account_holder_name;
      this.routingNumber = value?.routing_number;
      this.branchName = value?.branch_name;
      this.balance = value?.balance;
      this.accountStatus = value?.status;
      this.details = value?.details;

      this.showModal();
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/accounts/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Account Information Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },
    filterByTransactionDate() {
      this.handleDateRangeChange();
      this.loadItems();
    },
    filterByAccount() {
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      try {
        const [accountTransferHistory] = await Promise.all([
          this.getAccountTransferHistory({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterFromAccountId: this.filterFromAccountId,
            filterToAccountId: this.filterToAccountId,
          }),
        ]);

        const data = accountTransferHistory?.data?.data;
        const meta = accountTransferHistory?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.accountTransferValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.accountTransferValidation.reset();

            this.loading = true;
            await this.$api.post(
              "/api/accounts-transfers",

              {
                from_account_id: this.fromAccountId,
                to_account_id: this.toAccountId,
                amount: this.amount,
                account_holder_name: this.accountHolderName,
                remarks: this.remarks,
              }
            );
            this.loading = false;
            this.hiddenModal();

            this.loadItems();
            this.onCreateLoad();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Account Transfer Successfully Completed",
              },
            });
          } catch (error) {
            this.loading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.accountInfoValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.cell-with-description div {
  margin: 2px 0px;
}
</style>
